import { useMemo } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import type { PageProps } from 'gatsby'
import { ButtonLink } from 'src/components/ui/Button'

type Props = PageProps

const useErrorState = (location: Location) =>
  useMemo(() => {
    const params = new URLSearchParams(location.search)
    const fromUrl = decodeURI(params.get('from') ?? location.pathname)

    return {
      fromUrl,
    }
  }, [location.pathname, location.search])

function Page({ location }: Props) {
  const { fromUrl } = useErrorState(location)

  return (
    <section className="w-100 py-3 my-150px">
      <GatsbySeo noindex nofollow />
      <section className="flex flex-col mw-750-px j-center a-center mx-auto">
        <h1 className="section-title t-center">OPS! Página não encontrada</h1>
        <p className="t-center px-1">
          Parece que a página que você estava procurando não existe em nosso
          site, clique em um dos botões abaixo para continuar navegando pelo
          site!
        </p>
        <div className="flex py-2 flex-col mw-250-px a-center j-center mx-auto">
          <ButtonLink
            to="/parceiros"
            variant="secondary"
            className="t-center my-0-5"
          >
            Voltar para a página principal
          </ButtonLink>
        </div>
      </section>
    </section>
  )
}

export default Page
